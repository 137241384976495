<template>
  <v-app>

    <!-- <dashboard-core-app-bar /> -->
    <div class="wrap landing-page">
      <!-- <dashboard-core-drawer /> -->
      <v-container>
      <v-row>
        <trap-header-view/>
      </v-row>
      <v-row>
        <trap-base-view/>
      </v-row>
      </v-container>
    </div>

    <!-- <dashboard-core-settings /> -->
  </v-app>
</template>

<script>
export default {
  name: 'HomeTemplate',

  components: {
    //   DashboardCoreAppBar: () => import('./components/core/AppBar'),
    //   DashboardCoreDrawer: () => import('./components/core/Drawer'),
    //   DashboardCoreSettings: () => import('./components/core/Settings'),
    TrapHeaderView: () => import('./../../components/home/HomeAppBar'),
    TrapBaseView: () => import('./../../components/core/index/IndexView'),
  },

  data: () => ({
    expandOnHover: false,
  }),
}
</script>
